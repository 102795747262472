.row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -12px;
    margin-left: -12px;
    position: relative;
    margin-top: 5em;
    overflow-x: visible;
}

.testimonial_container {
    margin-top: 5em;
    display: flex;
    gap: 50px;
    flex-grow: 1;
}

.hero_img_container {
    position: relative;
    max-width: 40%;
    padding-right: 12px;
    padding-left: 12px;
    float: right;
}

.hero_image {
    float: right;
    width: 100%;
}

.subhead2 {
    font-weight: 500;
    font-size: 12px;
}

.head1 {
    font-weight: 500;
    font-size: 3em;
    color: #4b4b6d;
}

.head2 {
    margin-top: 1.7em;
    font-weight: 500;
    font-size: 2.4em;
    color: #4b4b6d;
}

.head3 {
    margin-top: 1.7em;
    font-weight: 400;
    font-size: 1.7em;
    line-height: 1.2em;
    color: #4b4b6d;
}

.head5 {
    font-weight: 300;
    font-size: 22;
    margin-top: 1.2em;
    color: #f5735a;
}

.content1 {
    font-weight: 500;
    font-size: 1.3em;
    line-height: 1.2em;
    color: #4b4b6d;
}

.content_container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.details {
    color: #373750;
    font-size: 20px;
}

.mob_show {
    display: none;
}

.content_flex {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

/* whole body approach */

.grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
}

.card_container {
    /* background: rgba(255, 238, 234, 0.28); */
    /* border: 2px solid #ffe6e1; */
    box-sizing: "border-box";
    border-radius: 24px;
    padding: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.card_container img {
    margin: 20px;
    width: 80%;
}

.card_container h3 {
    margin-top: 20px;
    font-size: 1em;
    color: #4b4b6d;
}

/* user form */
.form_container input:focus,
.form_container select:focus {
    outline-width: 0;
}

.form_container input {
    border: 2px solid #ffe6e1;
    font-size: 14px;
    border-radius: 14px;
    padding: 10px 20px;
    width: 100%;
    background-color: white;
}

.form_container select {
    border: 2px solid #ffe6e1;
    font-size: 14px;
    border-radius: 14px;
    padding: 10px;
    width: 100%;
    color: black;
    background-color: white;
    width: max-content;
}

.form_btn {
    background-color: #f5735a;
    color: white;
    border-width: 0;
    padding: 15px 44px;
    border-radius: 14px;
    font-weight: 500;
    font-size: 16px;
}

/* press releases */

.pressCard {
    background: rgba(255, 238, 234, 0.28);
    border: 2px solid #ffe6e1;
    border-radius: 14px;
    padding: 30px;
    margin: 10px;
    min-height: 523px;
    max-height: 523px;
}

.pressCard img {
    min-height: 91px;
}

.pressCard_name {
    color: #f5735a;
    font-weight: 500;
    margin-top: 90px;
    margin-bottom: 19px;
}

.pressCard_head {
    color: #4b4b6d;
    font-weight: 400;
    margin-top: 1em;
    margin-bottom: 1em;
}

.pressCard_date {
    color: #969cbc;
    font-weight: 400;
    margin: 1.2em 0;
}

.pressCard_content {
    margin-top: 2em;
    font-size: 1.2em;
    color: #4b4b6d;
    font-weight: 400;
    line-height: 1.3em;
}

.chat_widgit {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 20;
    background-color: rgb(71, 197, 71);
    border-radius: 24px;
}

.chat_widgit a {
    color: white;
}

.cal_widgit {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 20;
    /* background-color: rgb(245, 115, 90); */
    border-radius: 24px;
    color: white;
    padding: 10px 20px;
    cursor: pointer;
    font-weight: 500;
}

.modal_container {
    background-color: #413f3f6e;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
}

.modal iframe {
    border-width: 0;
    outline-width: 0;
}

.cal {
    min-width: 800px;
    max-width: 1000px;
    height: 680px;
}

.closeModal {
    position: fixed;
    color: white;
    font-size: 27px;
    font-weight: 500;
    top: 20px;
    right: 20px;
    z-index: 20;
    cursor: pointer;
}

@media only screen and (max-width: 500px) {
    .chat_widgit {
        position: fixed;
        right: 20px;
        bottom: 20px;
        z-index: 20;
        background-color: rgb(71, 197, 71);
        border-radius: 24px;
    }

    .chat_widgit a {
        color: white;
    }

    .cal_widgit {
        position: fixed;
        right: 26%;
        bottom: 20px;
        z-index: 20;
        /* background-color: rgb(245, 115, 90); */
        border-radius: 24px;
        color: white;
        padding: 10px 20px;
        cursor: pointer;
        font-weight: 500;
    }

    .pressCard {
        padding: 20px;
        max-height: 473px;
    }

    .pressCard img {
        width: 80%;
        min-height: 0px;
        max-height: 53px;
    }

    .pressCard_name {
        margin-top: 31px;
        margin-bottom: 15px;
        font-size: 20px;
    }

    .pressCard_head {
        font-size: 16px;
    }

    .pressCard_content {
        margin-top: 15px;
        font-weight: 500;
        font-size: 16px;
    }

    .mob_hide {
        display: none;
    }

    .mob_show {
        text-align: center;
        display: block;
    }

    .row {
        flex-direction: column-reverse;
        margin-top: 2em;
    }

    .hero_img_container {
        max-width: 100%;
        padding: 0;
        margin-right: -10px;
        z-index: 5;
    }

    .hero_image {
        height: 400px;
        width: calc(100% + 260px);
        margin-right: -140px;
    }

    .hero_head {
        position: absolute;
        z-index: 10;
        color: white !important;
        top: -400px;
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        width: 80%;
        margin-left: 10%;
    }

    .head3 {
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
    }

    .head2 {
        font-weight: 500;
        font-size: 24px;
    }

    .head5 {
        font-style: italic;
        font-weight: 300;
        font-size: 18px;
    }

    .testimonial_container {
        margin-top: 2em;
        display: flex;
        flex-direction: column;
        gap: 50px;
        flex-grow: 1;
    }

    .content1 {
        font-weight: 500;
        font-size: 20px;
    }

    .content_flex {
        flex-direction: column-reverse;
    }

    .grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .card_container {
        padding: 10px;
        margin: 0;
    }

    .card_container img {
        margin: 10px;
        width: 80%;
    }

    .card_container h3 {
        margin-top: 10px;
    }

    .cal {
        min-width: 95%;
        max-width: 95%;
        height: 90%;
    }

    .closeModal {
        font-size: 20px;
        top: 5px;
        right: 10px;
    }
}