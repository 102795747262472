.faq_card {
    cursor: pointer;
    padding: 17px 0;
    border-bottom: 1px solid rgba(235, 233, 233, 0.733);
}
.que {
    display: flex;
    justify-content: space-between;
}

.que:hover {
    color: rgb(245, 115, 90);
}
